<template>
  <div v-if="localCompany" class="fill-height overflow-y-auto">
    <div class="mx-auto" style="width: 640px; max-width: 640px;">
      <div class="d-flex flex-row align-center mt-2">
        <div class="subtitle-1" style="width: 100px">Название</div>
        <v-text-field
            v-model="localCompany.name"
            :rules="rulesName"
            outlined
            dense
            lang="ru"
            hide-details
            ref="name"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Описание</div>
        <v-textarea
            v-model="localCompany.description"
            :rules="rulesDescription"
            outlined
            dense
            lang="ru"
            hide-details
            ref="description"
            rows="2"
            auto-grow
        />
      </div>

      <div
        v-if="showLimits && ($store.persist.d.role === 'super' || $store.persist.d.role === 'dev')"
        class="d-flex flex-row align-center mt-4"
      >
        <div class="subtitle-1" style="width: 100px; line-height: 1;">Количество доступных запусков ИИ</div>
        <v-text-field
          v-model="localCompany.llm_runs_limits_count"
          :rules="rulesLimitCount"
          outlined
          dense
          lang="ru"
          rows="2"
          auto-grow
          type="number"
          ref="llm_runs_limits_count"
        />
      </div>

      <v-expand-transition>
        <div v-if="isError" class="pt-4">
          <v-alert type="error">
            Не все поля введены верно
          </v-alert>
        </div>
      </v-expand-transition>
      <v-btn depressed color="primary" block @click="save()" class="my-4">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCompany',
  props: {
    company: {
      type: Object,
      required: true,
    },
    showLimits: {
      type: Boolean,
      default: true,
    },
    companyString: {
      type: String,
    },
  },
  data: () => ({
    localCompany: null,
    isError: false,
  }),
  watch: {
    companyString: {
      immediate: true,
      handler() {
        this.localCompany = JSON.parse(JSON.stringify(this.company));
      },
    },
  },
  computed: {
    rulesName() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
    rulesDescription() {
      return [];
    },
    rulesLimitCount() {
      return [this.validateInteger, this.validateRange];
    },
  },
  methods: {
    validateInteger(value) {
      const integerPattern = /^-?\d+$/;
      if (integerPattern.test(value)) {
        return true;
      }
      return 'Введите целое число';
    },
    validateRange(value) {
      const num = parseInt(value, 10);
      const max = 999999; // this.localCompany?._freeLLMLimits ? this.localCompany._freeLLMLimits : 999999;
      if (num >= -1 && num <= max) {
        return true;
      }
      return `Число должно быть в диапазоне от -1 до ${max}`;
    },

    save() {
      this.$refs.name.validate();
      this.$refs.description.validate();

      if (this.showLimits && (this.$store.persist.d.role === 'super' || this.$store.persist.d.role === 'dev')) {
        this.$refs.llm_runs_limits_count.validate();
        if (!this.$refs.llm_runs_limits_count.valid) {
          this.isError = true;
          return;
        }
      }

      if (!this.$refs.name.valid || !this.$refs.description.valid) {
        this.isError = true;
        return;
      }

      this.isError = false;
      this.$emit('save', this.localCompany);
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <div
            class="card-button align-center my-2 pa-4"
            :class="cardClass"
    >
        <div class="d-flex flex-column">
          <div class="d-flex align-center mb-2">
            <div v-if="createdAt" class="caption">{{new Date(createdAt).toLocaleString('ru')}}</div>
            <v-spacer/>
          </div>
            <div class="d-flex align-center mb-1">
                <h4 v-if="name" class="flex-grow-1">{{ name }}</h4>
                <div v-else class="font-italic" style="font-size: 14px">Название не указано</div>
            </div>
          <div v-if="description" class="mb-1">{{ description }}</div>

          <div v-if="item">
            <div v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev' && item.llm_tokens_count !== undefined" class="mt-4">
              <div class="caption">Количество использованных токенов для запусков ИИ:</div>
              <div>
                {{item.llm_tokens_count}}
              </div>
            </div>
            <div v-if="item.llm_runs_count !== undefined" class="mt-4">
              <div class="caption">Количество завершенных запусков ИИ:</div>
              <div>{{item.llm_runs_count}}</div>
            </div>
            <div v-if="item.llm_runs_limits_count !== undefined" class="mt-4">
              <div class="caption">Количество доступных запусков ИИ:</div>
              <div v-if="item.llm_runs_limits_count === -1">
                Неограниченно
              </div>
              <div v-else>
                {{item.llm_runs_limits_count}}
              </div>
            </div>
<!--            <div v-if="item._freeLLMLimits !== undefined && item._freeLLMLimits !== null" class="mt-4">-->
<!--              <div class="caption">Количество незанятых доступных запусков ИИ:</div>-->
<!--              <div v-if="item._freeLLMLimits === -1">-->
<!--                Неограниченно-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                {{item._freeLLMLimits}}-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <v-spacer/>
        <div class="d-flex mt-4" style="gap:8px">
          <v-btn depressed @click="$emit('update')">Изменить данные компании</v-btn>
          <v-spacer/>
          <v-btn depressed color="error" @click="$emit('delete')">Удалить</v-btn>
        </div>
    </div>
</template>

<script>
export default {
  name: "CardCompany",
  props: ['name', 'description', 'createdAt', 'item'],
  computed: {
    cardClass() {
      return (this.$vuetify.breakpoint.smAndUp ? '' : 'card-button-mobile') + ' ' +
        (this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light');
    },
  },
};
</script>

<style scoped>

.card-button {
    border-radius: 5px;
    //width: 100%;
}

/*.card-button-mobile {*/
/*    width: 100%;*/
/*    padding: 12px 8px 8px 12px !important;*/
/*    min-height: inherit;*/
/*}*/

.theme--light.card-button {
    border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.card-button {
    background-color: #1E1E1E;
    color: #FFFFFF;
    border: thin solid rgba(255, 255, 255, 0.12);
}
</style>

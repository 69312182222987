<template>
  <LayoutOneColumn :maxWidth="850">
<!--    v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"-->
    <ViewCompanies @update="updateUsers" ref="companies" :value="value"/>
    <ViewUsers class="mt-4" @update="updateCompanies" ref="users" :isSuper="true" :value="value"/>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@/ui/layouts/LayoutOneColumn.vue";
import ViewCompanies from "@/ui/components/ViewCompanies.vue";
import ViewUsers from "@/ui/components/ViewUsers.vue";

export default {
  name: "PageControlCompaniesPanel",
  components: {ViewUsers, ViewCompanies, LayoutOneColumn},
  props: ['value'],
  methods: {
    async updateUsers() {
      await this.$refs.users.init();
    },
    async updateCompanies() {
      await this.$refs.companies.init();
    },
  },
};
</script>

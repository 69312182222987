<template>
    <LayoutOneColumn :maxWidth="850">

        <div class="mt-4">
          <template v-if="company">

            <div class="d-flex flex-column">
              <div class="d-flex align-center mb-2">
                <div v-if="company.createdAt" class="caption">{{new Date(company.createdAt).toLocaleString('ru')}}</div>
                <v-spacer/>
              </div>
              <div class="d-flex align-center mb-1">
                <h4 v-if="company.name" class="flex-grow-1">{{ company.name }}</h4>
                <div v-else class="font-italic" style="font-size: 14px">Название не указано</div>
              </div>
              <div v-if="company.description" class="mb-1">{{ company.description }}</div>

              <div v-if="company.llm_runs_count !== undefined" class="mt-4">
                <div class="caption">Количество завершенных запусков ИИ:</div>
                <div>{{company.llm_runs_count}}</div>
              </div>
              <div v-if="company.llm_runs_limits_count !== undefined" class="mt-4">
                <div class="caption">Количество доступных запусков ИИ:</div>
                <div v-if="company.llm_runs_limits_count === -1">
                  Неограниченно
                </div>
                <div v-else>
                  {{company.llm_runs_limits_count}}
                </div>
              </div>

              <div>
                <v-btn
                  v-if="$store.persist.d.role === 'admin' || $store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"
                  depressed block
                  @click="isShowCompanyProfileEditor = true"
                  class="my-4"
                >Редактировать</v-btn>
              </div>
            </div>

          </template>
        </div>

      <ViewUsers
        v-if="company && ($store.persist.d.role === 'admin' || $store.persist.d.role === 'super' || $store.persist.d.role === 'dev')"
        :companyId="company.id"
        :value="value"
        class="my-4"
      />

      <ViewDialog v-model="isShowCompanyProfileEditor" title="Изменение данных компании">
        <InputCompany
          :company="company"
          :showLimits="false"
          @save="updateCompany"
        />
      </ViewDialog>

    </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from '@/ui/layouts/LayoutOneColumn';
import InputCompany from "@/ui/components/InputCompany.vue";
import ViewUsers from "@/ui/components/ViewUsers.vue";
import ViewDialog from "@/ui/components/ViewDialog.vue";

export default {
  name: 'PageCompanyProfile',
  components: {ViewDialog, ViewUsers, InputCompany, LayoutOneColumn},
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          await this.fetchCompany();
        }
      },
    },
  },
  data: () => ({
    company: null,

    isShowCompanyProfileEditor: false,
  }),
  methods: {
    async updateCompany(companyData) {
      try {
        const response = await this.$api.main.postRequest('models/company', {
          id: companyData.id,
          name: companyData.name,
          description: companyData.description,
        });
        if (!response.success) {
          if (response.error === 'exist name') {
            this.$api.app.snackError('Компания с таким названием уже существует');
            return;
          }
        }
        await this.fetchCompany();
        this.$api.app.snackSuccess('Данные компании успешно изменены');
        this.isShowCompanyProfileEditor = false;
      } catch (e) {
        this.$api.app.snackError('Ошибка при изменении данных компании');
        console.error(e);
      }
    },
    async fetchCompany() {
      this.company = (await this.$api.main.getRequest('models/company/my')).data;
    },
  },
};
</script>

<style scoped>
</style>

import { render, staticRenderFns } from "./ViewCompanies.vue?vue&type=template&id=1afb38bc&scoped=true&"
import script from "./ViewCompanies.vue?vue&type=script&lang=js&"
export * from "./ViewCompanies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1afb38bc",
  null
  
)

export default component.exports